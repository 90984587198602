<template>
  <WidthWrapper type="section" class="py-40">
    <div class="px-20 lg:w-700 lg:mx-auto lg:px-0">
      <div class="text-grey-450 mb-20 mt-10 flex flex-wrap items-center">
        <NuxtLink :to="apiData.data.attributes.person.person_gender === 'girl' ? '/soeg-personer?person_gender=girl' : '/soeg-personer?person_gender=boy'">
          <div class="uppercase font-bold text-14 text-red-600 pr-10">{{ config.brandName }}-{{ apiData.data.attributes.person.person_gender === 'girl' ? 'pigen' : 'manden' }}</div>
        </NuxtLink>
        <Date v-if="apiData.data.attributes.published" class="text-14" :date="apiData.data.attributes.published" :updated-date="apiData.data.attributes.displayUpdatedTime ? apiData.data.attributes.changed : undefined" />
      </div>
      <PageTitle :title="apiData.data.attributes.title" />
    </div>
    <div class="relative mb-30 px-20 hidden lg:block">
      <ContentGallery v-if="apiData.data.attributes.gallery" :component-content="apiData.data.attributes" :title="false" :border="false" lazy-img="eager" img-sizing="(max-width: 768px) 100vw, 880px" />
    </div>
    <div class="relative mb-30 lg:hidden" v-cloak>
      <button v-if="prevActive" @click="gotoPrev" aria-label="Forrige" class="touch-manipulation h-40 w-35 bg-black/60 z-10 flex items-center justify-center focus:outline-none absolute top-1/2 left-0 inset-y-1/2">
        <div class="bg-svg-arrow2-white square-20 mr-4 bg-contain bg-center bg-no-repeat rotate-90 transform"></div>
      </button>
      <button v-if="nextActive" @click="gotoNext" aria-label="Næste" class="touch-manipulation h-40 w-35 bg-black/60 z-10 flex items-center justify-center focus:outline-none absolute top-1/2 right-0 inset-y-1/2">
        <div class="bg-svg-arrow2-white square-20 mr-4 bg-contain bg-center bg-no-repeat -rotate-90 transform"></div>
      </button>
      <div class="overflow-hidden content content-visibility">
        <div ref="sliderRef" class="overflow-x-scroll scroll-smooth snap-x snap-mandatory snap-slider-scroller">
          <div v-if="apiData.data.attributes.gallery" class="flex min-w-full">
            <div v-for="(media, index) in apiData.data.attributes.gallery.media" :key="index" class="flex-none w-full scroll-align-start scroll-stop-always">
              <div class="relative h-full" :key="media.imageUrl">
                <div class="relative bg-black-100 overflow-hidden">
                  <AllerImgixImg :media="media" width="320" height="480" sizes="(min-width: 768px) 320px, 100vw" :loading="index > 0 ? 'lazy' : 'eager'" :modifiers="{ fit: 'fill', ar: '2:3', filter: 'blur' }" />
                </div>
                <div class="w-full bg-grey-400 flex p-10 items-center justify-between">
                  <p class="credits text-13 text-white">{{ media.credits }}</p>
                  <div class="text-13 text-white">
                    <strong class="font-bold">{{ index + 1 }}</strong>
                    <span>/{{ apiData.data.attributes.gallery.media?.length }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lg:w-700 mx-20 lg:mx-auto">
      <div class="relative w-full">
        <div v-if="apiData.data.attributes.teaser" class="text-22 mb-10 leading-26 md:text-24 md:leading-32 font-bold">{{ apiData.data.attributes.teaser }}</div>
        <ContentComponents v-if="apiData.data.attributes.contentComponents" :content-components="apiData.data.attributes.contentComponents" class="relative text-19 leading-29" />
      </div>
      <div v-if="factCalc?.length" class="bg-grey-400 mb-30 px-25 py-30 text-white">
        <div class="text-28 font-khand leading-38 font-bold mb-10">Fakta</div>
        <template v-for="(fact, index) in factCalc">
          <template v-for="(value, prop) in fact" :key="'fact' + index">
            <div v-if="value" class="mb-10 last:mb-0 lg:mb-20 text-18">
              <strong class="font-bold">{{ prop }}: </strong>
              <span>{{ value }}</span>
            </div>
          </template>
        </template>
      </div>
    </div>
    <AdWrapper class="mb-30" :ad="{ slotId: 'cncpt-dsk_rec1_DF' }" grey-background allow-naughty-ad />
    <LazyMoreSHPerson :gender="apiData.data.attributes.person.person_gender" />
    <div class="lg:w-700 lg:mx-auto lg:px-0">
      <LazyPersonSearchForm :gender="apiData.data.attributes.person.person_gender" />
    </div>
    <LazyLatestArticles v-if="apiData.data.relationships.category.data.at(0)" :id="apiData.data.relationships.category.data.at(0).id" title="Flere frække artikler" />
  </WidthWrapper>
</template>

<script setup lang="ts">
const apiData = inject('apiData') as Aller.Article;
const { public: config } = useRuntimeConfig();

const {
  sliderRef,
  currentIndex,
  gotoPrev,
  gotoNext,
  prevActive,
  nextActive,
  observer,
  scrollListener,
  resize,
} = useAllerSlider({
  sliderConf: {
    responsive: [
      {
        breakpoint: 768,
        items: 1,
        stagePadding: 0,
        slidePadding: 0,
        navigation: false
      }]
  },
  lengthOverride: apiData.data.attributes.gallery?.media?.length || 0,
}, false);

const factCalc = computed(() => {
  let data = [
    { 'Alder': apiData.data.attributes.person.person_age ? `${apiData.data.attributes.person.person_age} år` : null },
    { 'By': apiData.data.attributes.person.person_city },
    { 'Højde': apiData.data.attributes.person.person_height ? `${apiData.data.attributes.person.person_height} cm` : null },
    { 'Vægt': apiData.data.attributes.person.person_weight ? `${apiData.data.attributes.person.person_weight} kg` : null },
    {
      'Stjernetegn': apiData.data.relationships.person_zodiac_sign && apiData.data.relationships.person_zodiac_sign.data && apiData.data.relationships.person_zodiac_sign.data.name && apiData.data.relationships.person_zodiac_sign.data.name[0]
        ? apiData.data.relationships.person_zodiac_sign.data.name[0].value
        : null
    },
    { 'Bryst mål': apiData.data.attributes.person.person_breast_size ? `${apiData.data.attributes.person.person_breast_size} cm` : null },
    { 'Hoftemål': apiData.data.attributes.person.person_hip_size ? `${apiData.data.attributes.person.person_hip_size} cm` : null },
    { 'Taljemål': apiData.data.attributes.person.person_waist_size ? `${apiData.data.attributes.person.person_waist_size} cm` : null },
    { 'Kæreste': apiData.data.attributes.person.person_bg },
    { 'Fritidsinteresse': apiData.data.attributes.person.person_hobbies },
    { 'Stilling': apiData.data.attributes.person.person_occupation },
    { 'Det tænder jeg på': apiData.data.attributes.person.person_what_turns_me_on },
    { 'Favorittøj': apiData.data.attributes.person.person_favorite_clothes },
    { 'Livret': apiData.data.attributes.person.person_favorite_dish },
    { 'Mit bedste feriested': apiData.data.attributes.person.person_fav_vacation_place },
    { 'Hvilke tre ting vil du tage med på en øde ø': apiData.data.attributes.person.person_three_things },
    { 'Beskriv dig selv med få ord': apiData.data.attributes.person.person_describe_yourself },
  ];
  if (apiData.data.attributes.person.person_gender === 'boy') {
    data.splice(5, 3);
  }

  // Let's not return empty values.
  return data.filter((elm) => Object.values(elm)[0] !== null);
});

const buildUrlSrc = (media: Aller.Media, modifiers: any) => {
  const { mediaSrc, defaultModifiers } = useAllerMedia(media);
  const img = useImage();

  return [].map((width: number) => {
    const mods = { ...defaultModifiers, ...modifiers, ...{ w: width } };
    return img(mediaSrc, mods) + ' ' + width + 'w';
  });
};

/**
 * Preload next image on index change.
 */
const preloadNext = (index: number) => {
  switched(index);
  if (index + 1 >= apiData.data.attributes.gallery?.media.length) {
    return;
  }
  const item = apiData.data.attributes.gallery?.media[index + 1];
  const windowW = window.outerWidth;
  const arCalc = windowW > 1279 ? '1050:750' : windowW > 1109 ? '800:650' : windowW > 1023 ? '474:550' : windowW > 767 ? '500:357' : windowW > 399 ? '320:464' : '320:364';
  const srcset = buildUrlSrc(item, { fit: 'contain', ar: arCalc, fill: 'blur' }).join(' ,');
  const preload = document.createElement('link');
  preload.id = 'gallery-preload-' + (index + 1).toString();
  preload.as = 'image';
  preload.rel = 'preload';
  preload.imageSrcset = srcset;
  preload.imageSizes = '100vw';
  if (!document.getElementById('gallery-preload-' + (index + 1).toString())) {
    document.head.append(preload);
  }
};

/**
 * Watch index, emit when it is changed.
 */
watch(currentIndex, () => {
  preloadNext(currentIndex.value);
});

/**
 * Vue mounted hook.
 * Setup listeners and resizeObserver
 *
 * @returns void
 */
onMounted((): void => {
  // Preload the second slide for mobile.
  if (window.outerWidth < 1024) {
    preloadNext(0);
  }

  if (sliderRef && sliderRef.value) {
    sliderRef.value.addEventListener('scroll', () => scrollListener(), { passive: true });
    observer.value = new ResizeObserver(() => {
      resize();
    });
    observer.value.observe(sliderRef.value);
  }
});

useHead({ meta: [{ name: 'rating', content: 'adult' }] });

/**
 * Push to datalayer when gallery slide switches.
 */
const switched = (e: any, screen: boolean = false) => {
  const route = useRoute();
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    page_url: route.fullPath || undefined,
    event: 'gallery_view',
    gallery_title: apiData.data.attributes.gallery?.title || undefined,
    gallery_index: e + 1 || undefined,
    gallery_total: apiData.data.attributes.gallery?.media?.length || undefined,
    fullscreen: screen
  });
}
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.snap-slider__scroller::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.snap-slider__scroller {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>
